import React, { useState } from "react";
import { EXCHANGE_URLS } from "../../URLS";
import axios from "axios";
import cogoToast from "cogo-toast";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function ProfilePassword() {
  const navigate = useNavigate();
  const [changePass, setChangePass] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const ChangePassApi = async (old, newP) => {
    const axiosConfig = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      const data = {
        currentPassword: old,
        newPassword: newP,
      };
      const res = await axios.post(
        `${EXCHANGE_URLS}/change-password`,
        data,
        axiosConfig
      );
      setChangePass({ currentPassword: "", newPassword: "" });
      if(res.ok){
        navigate("/dashboardd");
      }
      cogoToast.success("Password Changed Successfully");
    } catch (err) {
      cogoToast.error("Error");
    }
  };
  const handleChangePassword = () => {
    ChangePassApi(changePass?.currentPassword, changePass?.newPassword);
  };

  return (
    <Root>
      {" "}
      <h3>Change Your Password...</h3>
      <div className="main_div_pass">
        <div className="pass1">
          Enter Your Current Password
          <input
            type="password"
            placeholder="---Current Password---"
            value={changePass?.currentPassword}
            onChange={(e) =>
              setChangePass({ ...changePass, currentPassword: e.target.value })
            }
          />
        </div>
        <div className="pass1">
          Enter Your New Password
          <input
            type="Password"
            placeholder="---New Password---"
            value={changePass?.newPassword}
            onChange={(e) =>
              setChangePass({ ...changePass, newPassword: e.target.value })
            }
          />
        </div>
        <div className="box1">
          <button
            onClick={() => {
              handleChangePassword();
            }}
          >
            Set New Password
          </button>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  align-items: center;
  font-family: "Roboto", "sans-serif";

  h3 {
    font-weight: 500;
    font-family: "Roboto", "sans-serif";
  }
  .main_div_pass {
    box-shadow: 1px 1px 2px 1px black;
    border-radius: 20px;
    padding: 20px;
    .pass1 {
      display: flex;
      margin: 15px;
      font-size: 13px;
      flex-direction: column;
      input {
        text-align: center;
        border-radius: 20px;
        padding: 5px 10px;
        color: #202020;
        width: 240px;
        text-decoration: none;
        border: 1px solid black;
        @media (max-width: 600px) {
          min-width: 100px;
          width: 100%;
        }
      }
    }
    .box1 {
      text-align: center;
      button {
        background: #8656ec;
        color: #ffffff;
        padding: 5px 10px;
        border-color: transparent;
        font-size: 13px;
        text-align: center;
        margin: 5px;
        cursor: pointer;
        border-radius: 20px;
        background-size: 300% 100%;
        transition: all 0.2s ease-in-out 0s;
        text-transform: uppercase;
        &:hover {
          box-shadow: 1px 1px 4px 1px gray;
        }
      }
    }
  }
`;
