import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import logo from "../CommonPage/imageLogo/phanom.jpg";
import styled from "styled-components";
import { EXCHANGE_URLS_UNIVERSITY } from "../URLS";
import { RxCross2 } from "react-icons/rx";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  university_name: Yup.string().required("University name is required"),
  ambassador_name: Yup.string().required("Ambassador name is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be a number")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number must be at most 15 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  year_established: Yup.number()
    .required("Year of establishment is required")
    .positive()
    .integer()
    .min(1800, "Year must be after 1800")
    .max(new Date().getFullYear(), "Year cannot be in the future"),
  type: Yup.string()
    .oneOf(['private', 'government'], 'Invalid type selection')
    .required('Type is required'),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  // confirm_password: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match"),
  university_image: Yup.mixed().required("University image is required"),
  registration_certificate: Yup.mixed().required("Registration certificate is required"),
  gender: Yup.string()
    .oneOf(['male', 'female', 'other'], 'Invalid gender selection'),
  street_address: Yup.string().required("Street address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postal_code: Yup.string().required("Postal code is required"),
});

export default function UniRegister({ onClose }) {
  // const [universityImagePreview, setUniversityImagePreview] = useState("");
  // const [certificatePreview, setCertificatePreview] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      university_name: "",
      ambassador_name: "",
      phone_number: "",
      email: "",
      year_established: "",
      type: "",
      username: "",
      password: "",
      university_image: "",
      registration_certificate: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country:""
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();

      Object.keys(values).forEach((key) => {
        if (key.startsWith('street_address') || key.startsWith('city') || key.startsWith('state') || key.startsWith('postal_code')  || key.startsWith('country')) {
        
        } else if (values[key]) {
          data.append(key, values[key]);
        }
      });
      const addressFields = ['street_address', 'city', 'state', 'postal_code','country'];
      addressFields.forEach((field) => {
        if (values[field]) {
          data.append(`address[${field}]`, values[field]);
        }
      });

      try {
        const res = await axios.post(
          `${EXCHANGE_URLS_UNIVERSITY}/register`,
          data
        );
        if (res?.status === 200) {
          cogoToast.success("Registered Successfully");
          formik.resetForm();
          navigate("/unilogin");
        }
      } catch (err) {
        cogoToast.error("Registration Failed");
      }
    },
  });

  const selectContainerStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    padding: "10px",
    borderRadius: "10px",
    margin: "10px",
    border: "2px dashed #555",
    color: "#444",
    cursor: "pointer",
    transition: "background .2s ease-in-out, border .2s ease-in-out",
  };
  const isStepOneDataFilled = () => {
    return (
      formik.values.university_name &&
      formik.values.ambassador_name &&
      formik.values.phone_number &&
      formik.values.email &&
      formik.values.year_established &&
      formik.values.type &&
      // formik.values.gender &&
      formik.values.country &&
      formik.values.username &&
      formik.values.university_image &&
      formik.values.registration_certificate
    );
  };

  const handleRegisterClick = () => {
    if (step === 1) {
      if (isStepOneDataFilled()) {
        setStep(2);
      } else {
        cogoToast.warn("Please fill in all required fields");
      }
    } else if (step === 2) {
      formik.handleSubmit();
    }
  };

 
  const handleStepOneClick = () => {
    if (step === 2) {
      setStep(1);
    }
  };
  return (
    <Root>
      <div className="Container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="register-main-div">
              <div className="row">
                <div className="col-lg-12">
                  <div className="close_but">
                    <RxCross2 onClick={onClose} />
                  </div>
                </div>
              </div>
              <div className="row">
                {step === 1 && (
                  <>
                    <div className="col-lg-4  col-md-12 sub-first-div">
                      <div className="nameyyy">
                        <h6>Upload University Image</h6>
                        <div className="imgg" style={selectContainerStyle}>
                          Click Here
                          <img
                            src={
                              formik.values.university_image
                                ? URL.createObjectURL(
                                    formik.values.university_image
                                  )
                                : "https://www.crizac.co.uk/catalog/assets/images/upload_icon.svg"
                            }
                            alt="img"
                            className="inside_img"
                          />
                          <input
                            type="file"
                            name="university_image"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "university_image",
                                e.target.files[0]
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="nameyyy">
                        <h6> Registration Certificate</h6>
                        <div className="imgg" style={selectContainerStyle}>
                          Click Here
                          <img
                            src={
                              formik.values.registration_certificate
                                ? URL.createObjectURL(
                                    formik.values.registration_certificate
                                  )
                                : "https://www.crizac.co.uk/catalog/assets/images/upload_icon.svg"
                            }
                            alt="Upload"
                            className="inside_img"
                          />
                          <input
                            type="file"
                            name="registration_certificate"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "registration_certificate",
                                e.target.files[0]
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 sub-second-div">
                      <h3 className="text-center">Create account</h3>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label>University name </label>
                          <br></br>
                          <input
                            type="text"
                            name="university_name"
                            value={formik.values.university_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.university_name &&
                          formik.errors.university_name ? (
                            <div className="error">
                              {formik.errors.university_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Ambassador name </label>
                          <br></br>
                          <input
                            type="text"
                            name="ambassador_name"
                            value={formik.values.ambassador_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.ambassador_name &&
                          formik.errors.ambassador_name ? (
                            <div className="error">
                              {formik.errors.ambassador_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Email Address</label>
                          <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Phone Number </label>
                          <input
                            type="number"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phone_number &&
                          formik.errors.phone_number ? (
                            <div className="error">
                              {formik.errors.phone_number}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>User name </label>
                          <input
                            type="text"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.username && formik.errors.username ? (
                            <div className="error">
                              {formik.errors.username}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                        <select
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="" label="Select gender" />
                          <option value="male" label="Male" />
                          <option value="female" label="Female" />
                          <option value="other" label="Other" />
                        </select>
                      
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Country </label>
                          <input
                             type="text"
                             name="country"
                             value={formik.values.country}
                             onChange={formik.handleChange}
                             onBlur={formik.handleBlur}
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="error">
                              {formik.errors.country}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                        
                          <select
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="" label="Select type" />
                            <option value="private" label="Private" />
                            <option value="government" label="Government" />
                          </select>
                          {formik.touched.type && formik.errors.type ? (
                            <div className="error">{formik.errors.type}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Year Of Established</label>
                          <input
                            type="number"
                            name="year_established"
                            value={formik.values.year_established}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.year_established &&
                          formik.errors.year_established ? (
                            <div className="error">
                              {formik.errors.year_established}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-lg-6  col-md-6 col-6 mt-3">
                          <button onClick={handleRegisterClick}>
                            {" "}
                            Save & Proceed{" "}
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-one">1</h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-two">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <h6>Already have an account?</h6>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a
                              href="/unilogin"
                              onClick={() => {
                                navigate("/unilogin");
                              }}
                            >
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {step === 2 && (
                  <>
                    <div className="col-lg-12 sub-second-div">
                      <h3 className="text-center">Create account</h3>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label> Street Address </label>
                          <br></br>
                          <input
                          type="text"
                          name="street_address"
                          value={formik.values.street_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.street_address && formik.errors.street_address ? (
                          <div className="error">{formik.errors.street_address}</div>
                        ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> City </label>
                          <br></br>
                          <input
                          type="text"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <div className="error">{formik.errors.city}</div>
                        ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> State</label>
                          <input
                          type="text"
                          name="state"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.state && formik.errors.state ? (
                          <div className="error">{formik.errors.state}</div>
                        ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Postal Code </label>
                          <input
                          type="text"
                          name="postal_code"
                          value={formik.values.postal_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.postal_code && formik.errors.postal_code ? (
                          <div className="error">{formik.errors.postal_code}</div>
                        ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Password</label>
                          <input
                          type="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error">{formik.errors.password}</div>
                        ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Confirm Password</label>
                          <input
                          type="text"
                          // name="confirm_password"
                          // value={formik.values.confirm_password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.confirm_password && formik.errors.confirm_password ? (
                          <div className="error">{formik.errors.confirm_password}</div>
                        ) : null} */}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6  col-md-6 col-6 mt-3">
                          <button type="submit" onClick={handleRegisterClick}>
                            Sign In
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6
                            className="indication-btn-one"
                            onClick={handleStepOneClick}
                          >
                            1
                          </h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-twoo">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <h6>Already have an account?</h6>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a href="/unilogin">
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .close_but {
    display: flex;
    justify-content: flex-end;
    svg {
      width: 50px;
      height: 20px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .register-main-div {
    box-shadow: 1px 1px 4px 1px gray;
    background-color: #fff;
    margin: 80px 0 50px 0;
    border-radius: 20px;
  }

  .sub-first-div {
    border: none;
  }

  .sub-first-div .logo_div {
    padding-left: 30px;
    margin-top: 10px;
  }

  .sub-second-div {
    border: none;
    padding-right: 50px;
    padding-bottom: 10px;
    padding: 0 100px 10px;
  }

  .sub-second-div h3 {
    padding-top: 10px;
  }

  .sub-second-div label {
    color: #4a4a4a;
    font-size: 12px;
    padding-left: 10px;
  }

  .sub-second-div input {
    border-radius: 10px;
    color: grey;
    height: 32px;
    border: 1px solid black;
    width: 100%;
  }

  select {
    border: 1px solid black;
    margin-top: 22px;
    font-size: 12px;
    color: #4a4a4a;
    width: 100%;
    height: 32px;
    border-radius: 10px;
  }
  .sub-second-div button {
    border: none;
    background-color: #8656ec;
    color: white;
    padding: 7px 20px;
    width: 100%;
    border-radius: 20px;
  }

  .indication-btn-one {
    color: white;
    background-color: #8656ec;
    margin-top: 20px;
    font-size: 12px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .indication-btn-two {
    color: white;
    background-color: #c3c0c8;
    margin-top: 20px;
    font-size: 12px;
    padding: 5px 0px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .login-content-ist {
    border: none;
    height: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .login-content-ist h6 {
    color: #4a4a4a;
    font-size: 15px;
    text-decoration: none;
  }

  .login-content-second {
    border: none;
    height: 50px;
  }

  .login-content-second a {
    color: #8656ec !important;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
  }

  @media (max-width: 567px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 28px;
    }
    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .indication-btn-twoo {
    color: white;
    background-color: #8656ec;
    margin-top: 20px;
    font-size: 12px;
    padding: 5px 0px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 60%;
    }

    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }

    .nameyyy {
      height: 253px !important;
      margin-left: 92px !important;
    }

    .sub-second-div h3 {
      padding-top: 35px;
    }
  }

  .nameyyy {
    display: flex;
    flex-direction: column;
    font-size: small;
    width: 100%;
    margin-right: 10px;
    color: black;
    padding: 30px 0 0 20px;
    height: 180px;
    margin-left: 30px;
    input {
      border-radius: 10px;
      padding: 5px;
      color: #202020;
      width: 90%;
      text-decoration: none;
      border: 2px solid #a5d8fa;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    input:focus,
    input:active {
      border-color: #ff6525;
    }

    .imgg {
      text-align: center;
      position: relative;
      cursor: pointer;
      width: 70%;
      height: 40%;
      &:hover {
        opacity: 0.5;
      }
      .inside_img {
        width: 25%;
        object-fit: contain;
      }
      input {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
      }
    }
  }

  input[type="file"]::file-selector-button {
    border: 1px solid #119af6;
    padding: 0.2em 0.4em;
    border-radius: 0.4em;
    background-color: #62bdfa;
    transition: 1s;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
  }

  input[type="file"]::file-selector-button:hover {
    background-color: #81ecec;
    border: 1px solid #00cec9;
  }
`;
