import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  EXCHANGE_URLS_ADMIN,
  EXCHANGE_URLS_APPLICATION,
  EXCHANGE_URLS_UNIVERSITY,
} from "../../URLS";
import Documents from "./Documents";
import cogoToast from "cogo-toast";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  student_firstname: yup.string().required("First Name is required"),
  student_lastname: yup.string().required("Last Name is required"),
  student_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  student_passport_no: yup.string().required("Passport number is required"),
  student_whatsapp_number: yup
    .string()
    .required("Whatsapp number is required")
    .matches(/^\d{10}$/, "Whatsapp number must be exactly 10 digits"),
  marital_status: yup.string().required("Marital status is required"),
  previous_visa_refusals: yup.string().required("Visa refusal is required"),
  ielts_reading: yup
    .number()
    .required("IELTS reading score is required")
    .max(5, "IELTS reading score cannot be more than 5"),
  ielts_listening: yup
    .number()
    .required("IELTS listening score is required")
    .max(5, "IELTS listening score cannot be more than 5"),
  ielts_writing: yup
    .number()
    .required("IELTS writing score is required")
    .max(5, "IELTS writing score cannot be more than 5"),
  ielts_speaking: yup
    .number()
    .required("IELTS speaking score is required")
    .max(5, "IELTS speaking score cannot be more than 5"),
  university_id: yup.string().required("University is required"),
  country_id: yup.string().required("Country is required"),
  course_id: yup.string().required("Course is required"),
  gender: yup.string().required("Gender is required"),
  staff_id: yup.string().required("Staff is required"),
});

export default function Applications() {
  // const [data, setData] = useState({
  //   course_id: 0,
  //   university_id: 0,
  //   student_firstname: "",
  //   student_lastname: "",
  //   student_email: "",
  //   student_whatsapp_number: "",
  //   student_passport_no: "",
  //   marital_status: "",
  //   previous_visa_refusals: "",
  //   ielts_reading: 0,
  //   ielts_listening: 0,
  //   ielts_writing: 0,
  //   ielts_speaking: 0,
  //   country_id: "",
  //   gender: "",
  //   staff_id: "",
  // });

  const formik = useFormik({
    initialValues: {
      course_id: 0,
      student_firstname: "",
      student_lastname: "",
      student_email: "",
      student_passport_no: "",
      student_whatsapp_number: "",
      marital_status: "",
      previous_visa_refusals: "",
      ielts_reading: "",
      ielts_listening: "",
      ielts_writing: "",
      ielts_speaking: "",
      university_id: "",
      country_id: "",
      gender: "",
      staff_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      appApi(values);
    },
  });
  const [activeNext, setActiveNext] = useState(true);
  const [applicationId, setApplicationId] = useState("");
  const [course, setCourse] = useState([]);
  const [university, setUniversity] = useState([]);
  const [countryy, setCountryy] = useState([]);
  const [getStaff, setGetstaff] = useState([]);

  const appApi = async (data) => {
    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.post(
        `${EXCHANGE_URLS_APPLICATION}/addappplications`,
        data,
        axiosConfig
      );

      if (res?.status === 200) {
        setApplicationId(res?.data?.data.id);
        setActiveNext(false);
        cogoToast.success("Application added successfully");
      }
    } catch (err) {
      cogoToast.warn("Something went wrong");
    }
  };

  const getCourse = async () => {
    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${EXCHANGE_URLS_UNIVERSITY}/all/courseonly`,
        axiosConfig
      );
      if (res?.status === 200) {
        setCourse(res?.data.data);
        setUniversity(res?.data?.data);
      }
    } catch (err) {
      cogoToast.warn("something went wrong");
    }
  };
  const getCountry = async () => {
    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${EXCHANGE_URLS_ADMIN}/getallcountry/`,
        axiosConfig
      );
      if (res?.status === 200) {
        setCountryy(res?.data.data);
      }
    } catch (err) {
      cogoToast.warn("error");
    }
  };

  const getUniversity = async () => {
    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${EXCHANGE_URLS_UNIVERSITY}/getall/university`,
        axiosConfig
      );
      if (res?.status === 200) {
        setUniversity(res?.data?.data.allUsers);
      }
    } catch (err) {
      cogoToast.warn("error");
    }
  };
  const getstaff = async () => {
    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        `${EXCHANGE_URLS_UNIVERSITY}/allstaffget`,
        axiosConfig
      );
      if (res?.status === 200) {
        setGetstaff(res?.data?.data);
      }
    } catch (err) {
      cogoToast.warn("error");
    }
  };

  

  useEffect(() => {
    getCourse();
    getUniversity();
    getCountry();
    getstaff();
  }, []);

  return (
    <Root>
      <form onSubmit={formik.handleSubmit}>
        {activeNext ? (
          <div className="first_div">
            <div className="heading">
              <h2>Apply For New Courses</h2>
            </div>
            <div className="first_box1">
              <div className="textarea">
                <div className="name">
                  {" "}
                  First Name
                  <input
                    type="text"
                    name="student_firstname"
                    value={formik.values.student_firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="First Name"
                  />
                  {formik.touched.student_firstname &&
                  formik.errors.student_firstname ? (
                    <div>{formik.errors.student_firstname}</div>
                  ) : null}
                </div>
                <div className="name">
                  Last Name
                  <input
                    type="text"
                    name="student_lastname"
                    value={formik.values.student_lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Last Name"
                  />
                  {formik.touched.student_lastname &&
                  formik.errors.student_lastname ? (
                    <div>{formik.errors.student_lastname}</div>
                  ) : null}
                </div>
              </div>
              <div className="textarea">
                <div className="name">
                  E-Mail
                  <input
                    type="email"
                    name="student_email"
                    value={formik.values.student_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Ex-Phanom@gmail.com"
                  />
                  {formik.touched.student_email &&
                  formik.errors.student_email ? (
                    <div>{formik.errors.student_email}</div>
                  ) : null}
                </div>
                <div className="name">
                  Passport no
                  <input
                    type="text"
                    name="student_passport_no"
                    value={formik.values.student_passport_no}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Passport no"
                  />
                  {formik.touched.student_passport_no &&
                  formik.errors.student_passport_no ? (
                    <div>{formik.errors.student_passport_no}</div>
                  ) : null}
                </div>
              </div>
              <div className="textarea">
                <div className="name">
                  {" "}
                  Whatsapp No
                  <input
                    type="number"
                    name="student_whatsapp_number"
                    value={formik.values.student_whatsapp_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Whatsapp No"
                  />
                  {formik.touched.student_whatsapp_number &&
                  formik.errors.student_whatsapp_number ? (
                    <div>{formik.errors.student_whatsapp_number}</div>
                  ) : null}
                </div>

                <div className="name">
                  {" "}
                  Marital status
                  <select
                    name="marital_status"
                    value={formik.values.marital_status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Marital Status</option>
                    <option value="married">Married</option>
                    <option value="unmarried">Unmarried</option>
                  </select>
                  {formik.touched.marital_status &&
                  formik.errors.marital_status ? (
                    <div>{formik.errors.marital_status}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="first_box1">
              <div className="textarea">
                <div className="name">
                  {" "}
                  Visa Refusal
                  <select
                    name="previous_visa_refusals"
                    value={formik.values.previous_visa_refusals}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Visa</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {formik.touched.previous_visa_refusals &&
                  formik.errors.previous_visa_refusals ? (
                    <div>{formik.errors.previous_visa_refusals}</div>
                  ) : null}
                </div>
                <div className="name">
                  {" "}
                  Ielts reading
                  <input
                    type="number"
                    name="ielts_reading"
                    value={formik.values.ielts_reading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Reading"
                  />
                  {formik.touched.ielts_reading &&
                  formik.errors.ielts_reading ? (
                    <div>{formik.errors.ielts_reading}</div>
                  ) : null}
                </div>
              </div>
              <div className="textarea">
                <div className="name">
                  {" "}
                  Ielts listening
                  <input
                    type="number"
                    name="ielts_listening"
                    value={formik.values.ielts_listening}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Listening"
                  />
                  {formik.touched.ielts_listening &&
                  formik.errors.ielts_listening ? (
                    <div>{formik.errors.ielts_listening}</div>
                  ) : null}
                </div>
                <div className="name">
                  {" "}
                  Ielts writing
                  <input
                    type="number"
                    name="ielts_writing"
                    value={formik.values.ielts_writing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Writing"
                  />
                  {formik.touched.ielts_writing &&
                  formik.errors.ielts_writing ? (
                    <div>{formik.errors.ielts_writing}</div>
                  ) : null}
                </div>
              </div>
              <div className="textarea">
                <div className="name">
                  {" "}
                  Ielts speaking
                  <input
                    type="number"
                    name="ielts_speaking"
                    value={formik.values.ielts_speaking}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Speaking"
                  />
                  {formik.touched.ielts_speaking &&
                  formik.errors.ielts_speaking ? (
                    <div>{formik.errors.ielts_speaking}</div>
                  ) : null}
                </div>
                <div className="name">
                  {" "}
                  University Name
                  <select
                    name="university_id"
                    value={formik.values.university_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">University</option>
                    {university &&
                      university.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.university_name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.university_id &&
                  formik.errors.university_id ? (
                    <div>{formik.errors.university_id}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="first_box1">
              <div className="textarea">
                <div className="name">
                  {" "}
                  Country Name
                  <select
                    name="country_id"
                    value={formik.values.country_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Country</option>
                    {countryy &&
                      countryy.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.country_name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div>{formik.errors.country_id}</div>
                  ) : null}
                </div>
                <div className="name">
                  {" "}
                  Courses
                  <select
                    name="course_id"
                    value={formik.values.course_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Courses</option>
                    {course &&
                      course.map((data, index) => (
                        <option key={index} value={data.course_id}>
                          {data.course_name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.course_id && formik.errors.course_id ? (
                    <div>{formik.errors.course_id}</div>
                  ) : null}
                </div>
              </div>
              <div className="textbutton">
                <div className="name">
                  {" "}
                  Select Gender
                  <select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {formik.touched.gender && formik.errors.gender ? (
                    <div>{formik.errors.gender}</div>
                  ) : null}
                </div>
                <div className="name">
                  {" "}
                  Staff Name
                  <select
                    name="staff_id"
                    value={formik.values.staff_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Staff</option>
                    {getStaff &&
                      getStaff.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.staff_name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.staff_id && formik.errors.staff_id ? (
                    <div>{formik.errors.staff_id}</div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Documents appId={applicationId} />
        )}
      </form>
    </Root>
  );
}
const Root = styled.section`
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  @media (max-width: 788px) {
    padding-left: 60px;
    flex-direction: column;
  }
  .first_div {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;

    .heading {
      h2 {
        font: 20px "Roboto", sans-serif;
        color: #202020;
        font-weight: 600;
        padding: 10px;
        display: flex;
        margin: 0;
      }
    }
    @media (max-width: 709px) {
      flex-direction: column;
    }

    .first_box1 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0px 10px;
      padding: 10px;
      width: 80%;
      box-shadow: 1px 1px 6px 1px lightgray;
      border-radius: 10px;

      @media (max-width: 850px) {
        padding: 0;
      }
      .textarea {
        display: flex;
        background-color: white;
        justify-content: space-between;
        @media (max-width: 700px) {
          flex-direction: column;
        }
        .name {
          display: flex;
          flex-direction: column;
          width: 50%;
          padding: 7px;
          font-size: 14px;
          justify-content: center;
          color: #202020;
          background-color: white;
          @media (max-width: 700px) {
            width: 80%;
            flex-direction: column;
          }
        }
      }
      .textbutton {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media (max-width: 850px) {
          flex-direction: column;
        }
        .name {
          display: flex;
          flex-direction: column;
          width: 50%;
          padding: 7px;
          font-size: 13px;
          justify-content: center;
          color: #202020;
          background-color: white;
          @media (max-width: 700px) {
            width: 80%;
            flex-direction: column;
          }
        }
        button {
          display: flex;
          flex-direction: column;
          width: 150px;
          padding: 5px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-top: 10px;
          border-radius: 10px;
          border: transparent;
          background: #8656ec;
          color: #fff;
          background-size: 300% 100%;
          transition: all 0.3s ease-in-out 0s;
          text-transform: uppercase;
          &:hover {
            background: #6d32eb;
            transition: all 0.1s ease-in-out 0s;
            box-shadow: 4px 5px 5px gray;
          }
        }
      }
      input {
        border-radius: 10px;
        padding: 5px;
        color: #202020;
        text-decoration: none;
        border: 1px solid gray;
        &:hover {
          box-shadow: 4px 4px 5px darkgray;
          transition: all 0.1s ease-in-out 0s;
        }
        @media (max-width: 600px) {
          min-width: 100px;
          width: 100%;
        }
      }
      select {
        background-color: white;
        color: black;
        text-decoration: none;
        border: 1px solid gray;
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, black 50%),
          linear-gradient(135deg, black 50%, transparent 50%),
          linear-gradient(to right, #8656ec, #c6abff);
        background-position: calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px), 100% 0;
        background-size: 5px 5px, 5px 5px, 40px 45px;
        background-repeat: no-repeat;
        &:hover {
          box-shadow: 4px 4px 5px darkgray;
          transition: all 0.1s ease-in-out 0s;
        }
        @media (max-width: 555px) {
          padding: 8px;
          background-size: 5px 5px, 5px 5px, 30px 45px;
          align-items: center;
        }
      }
    }
  }
`;
