import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EXCHANGE_URLS } from "../URLS";
import axios from "axios";
import cogoToast from "cogo-toast";
import { RxCross2 } from "react-icons/rx";
import * as Yup from 'yup';
import { useFormik } from "formik";
// import logo from "../CommonPage/imageLogo/phanom.jpg";
// import rect from "../CommonPage/imageLogo/rect.png";



const validationSchema = Yup.object({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone_number: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  street_address: Yup.string().required('Street Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  postal_code: Yup.string().required('Postal Code is required'),
});
  
export default function Partner({ onClose }) {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone_number: '',
      username: '',
      password: '',
      confirm_password: '',
      street_address: '',
      city: '',
      state: '',
      postal_code: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { confirm_password, ...data } = values;
      try {
        const res = await axios.post(`${EXCHANGE_URLS}/registerss`, {
          ...data,
          address: {
            street_address: values.street_address,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
          },
        });

        if (res?.status === 201) {
          cogoToast.success('Registered Successfully');
          formik.resetForm();
          setStep(1);
          navigate('/login');
        }
      } catch (err) {
        cogoToast.error('Error');
      }
    },
  });

  const isStepOneDataFilled = () => {
    return (
      formik.values.firstname &&
      formik.values.lastname &&
      formik.values.email &&
      formik.values.phone_number &&
      formik.values.username 
    );
  };
  const handleRegisterClick = () => {
    if (step === 1) {
      if (isStepOneDataFilled) {
        setStep(2);
      } else {
        cogoToast.warn('Please fill in all required fields');
      }
    } else if (step === 2) {
      formik.handleSubmit();
    }
  };
  

  const handleStepOneClick = () => {
    if (step === 2) {
      setStep(1);
    }
  };
  return (
    <Root>
      <div className="Container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="register-main-div">
              <div className="row">
                <div className="col-lg-12">
                  <div className="close_but">
                    <RxCross2 onClick={onClose} />
                  </div>
                </div>
              </div>
              <div className="row">
              

                <div className="col-lg-12 sub-second-div">
                  <h3 className="text-center">Create account</h3>
                  {step === 1 && (
                    <>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label>First name </label>
                          <br/>
                          <input
                            type="text"
                            name="firstname"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.firstname && formik.errors.firstname ? (
                            <div className="error">{formik.errors.firstname}</div>
                          ) : null}
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Last name </label>
                          <input
                            type="text"
                            name="lastname"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.lastname && formik.errors.lastname ? (
                            <div className="error">{formik.errors.lastname}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Email Address</label>
                          <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Phone Number </label>

                          <input
                            type="text"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.phone_number && formik.errors.phone_number ? (
                            <div className="error">{formik.errors.phone_number}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>User name </label>

                          <input
                            type="text"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.username && formik.errors.username ? (
                            <div className="error">{formik.errors.username}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6  mt-3">
                          <button onClick={handleRegisterClick}>Save & Proceed</button>
                        </div>
                        
                      </div>

                     

                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-one">1</h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-two">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <a href="/">Already have an account?</a>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a
                              href="/login"
                              onClick={() => {
                                navigate("/login");
                              }}
                            >
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="row">
           

                <div className="col-lg-12 sub-second-div">
                  {step === 2 && (
                    <>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label> Street Address </label>
                          <br></br>
                          <input
                            type="text"
                            name="street_address"
                            value={formik.values.street_address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.street_address && formik.errors.street_address ? (
                            <div className="error">{formik.errors.street_address}</div>
                          ) : null}
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <label>City</label>
                          <br></br>

                          <input
                            type="text"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> State</label>
                          <input
                            type="text"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="error">{formik.errors.state}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Postal Code </label>
                          <input
                            type="text"
                            name="postal_code"
                            value={formik.values.postal_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.postal_code && formik.errors.postal_code ? (
                            <div className="error">{formik.errors.postal_code}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Password</label>
                          <input
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="error">{formik.errors.password}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Confirm Password</label>
                          <input
                            type="password"
                            name="confirm_password"
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.confirm_password && formik.errors.confirm_password ? (
                            <div className="error">{formik.errors.confirm_password}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-2">
                        {/* <input
                          type="date"
                          value={formData?.dob}
                          onChange={(e) => {
                            setFormData({ ...formData, dob: e.target.value });
                          }}
                        /> */}

                        <div className="col-lg-4  col-md-5 col-4 mt-3"></div>

                        <div className="col-lg-6  col-md-6 col-6 mt-3">
                          <button type="submit" onClick={handleRegisterClick}>
                            Sign In
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-one"  onClick={handleStepOneClick}>1</h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-two">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <h6>Already have an account?</h6>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a href="/login">
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2"></div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .close_but {
    display: flex;
    justify-content: flex-end;
    svg {
      width: 50px;
      height: 20px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .register-main-div {
    box-shadow: 1px 1px 4px 1px gray;
    background-color: #fff;
    margin: 80px 0 50px 0;
    border-radius: 20px;
  }

  .sub-first-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: none;
  }

  .log_img {
    display: flex;
    justify-content: center;
  }
  .main_img {
    padding: 0 20px;
    img {
      width: 100%;
      height: 300px;
    }
  }
  .sub-first-div .logo_div {
    margin-top: 10px;
  }

  .sub-second-div {
    border: none;
    padding: 0 100px 10px;
  }

  .sub-second-div h3 {
    padding-top: 10px;
  }

  .sub-second-div label {
    color: #4a4a4a;
    font-size: 12px;
    padding-left: 10px;
  }

  .sub-second-div input {
    border-radius: 10px;
    color: grey;
    height: 32px;
    border: 1px solid black;
    width: 100%;
  }

  select {
    border: 1px solid black;
    margin-top: 22px;
    font-size: 12px;
    color: #4a4a4a;
    width: 100%;
    height: 32px;
    border-radius: 10px;
  }
  .sub-second-div button {
    border: none;
    background-color: #8656ec;
    color: white;
    width: 100%;
    padding: 7px 20px;
    border-radius: 20px;
  }

  .indication-btn-one {
    color: white;
    background-color: #8656ec;
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    border-radius: 20px;
    padding: 5px 0px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .indication-btn-two {
    color: white;
    background-color: #c3c0c8;
    margin-top: 20px;
    font-size: 12px;
    padding: 5px 0px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .login-content-ist {
    border: none;
    height: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .login-content-ist a {
    color: #4a4a4a;
    font-size: 15px;
    text-decoration: none;
  }

  .login-content-second {
    border: none;
    height: 50px;
  }

  .login-content-second a {
    color: #8656ec !important;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
  }

  @media (max-width: 567px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 28px;
    }
    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 60%;
    }

    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }
  }
`;
