import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    const data = props.data || {};
    const seriesData = [
      data.approved || 0,
      data.totalApplications || 0,
      data.pending || 0,
    ];

    this.state = {
      series: seriesData,
      options: {
        chart: {
          type: "donut",
        },
        labels: ["Approved", "Total Applications", "Pending"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400,
                type: "donut",  
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <Root id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
        />
      </Root>
    );
  }
}

export default DonutChart;

const Root = styled.section`
  .apexcharts-datalabel,
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value,
  .apexcharts-datalabels,
  .apexcharts-pie-label {
    cursor: default;
    pointer-events: none;
    /* fill: black; */
    font-size: 15px;
  }
`;
