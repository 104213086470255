import React from "react";
import ReactApexChart from "react-apexcharts";

class BarCharts extends React.Component {
  constructor(props) {
    super(props);
   
    const seriesData = props.data.data
      ? props.data.data.map((item) => item.total_sales)
      : [];

    const categories = props.data.data
      ? props.data.data.map((item) => item.month)
      : [];

   
    this.state = {
      series: [{ name: "Total Applications", data: seriesData }],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories,
          title: {
            text: "Months",
          },
        },
        yaxis: {
          // categories: ["10",20,30,40,50,60,70,80,90,100],
          title: {
            text: "Total Applications",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " Applications";
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}
export default BarCharts;
