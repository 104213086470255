import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexCharts extends React.Component {
  constructor(props) {
    super(props);
    const seriesData = Array.isArray(props.data)
      ? props.data.map((item) => item.total_sales)
      : [];

    const categories = Array.isArray(props.data)
      ? props.data.map((item) => item.day)
      : [];

    this.state = {
      series: [{ name: "Total Applications", data: seriesData }],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: categories,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}
export default ApexCharts;
