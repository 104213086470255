import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DonutChart from "./DonutChart";
import axios from "axios";
import { EXCHANGE_URLS_ADMIN, EXCHANGE_URLS_APPLICATION } from "../../../URLS";
import ApexCharts from "./ApexCharts";
import BarCharts from "./BarCharts";
import cogoToast from "cogo-toast";

export default function MainChart() {
  const [line, setLine] = useState();
  const [dashboardVal, setDashboardVal] = useState();
  const [bar, setBar] = useState([]);
  const [selection, setSelection] = useState("2023");

  const dashboardDonutApi = async () => {
    const axiosConfig = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${EXCHANGE_URLS_APPLICATION}/count`,
        axiosConfig
      );
 
      setDashboardVal(res?.data?.data);
    } catch (err) {
      cogoToast.error("Error");

    }
  };
  const dashboardLineApi = async () => {
    const axiosConfig = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${EXCHANGE_URLS_ADMIN}/getsalescurrentmonth`,
        axiosConfig
      );
      if(res.status === 201){
      
        setLine(res?.data);
      }
    } catch (err) {
      cogoToast.error("Error");

    }
  };
  const dashboardBarApi = async () => {
    const axiosConfig = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${EXCHANGE_URLS_ADMIN}/getsales?year=${selection}`,
        axiosConfig
      );
      setBar(res?.data);
    } catch (err) {
      cogoToast.error("Error");

    }
  };

  useEffect(() => {
    dashboardDonutApi();
    dashboardLineApi();
    dashboardBarApi();
  }, [selection]);
  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };
  return (
    <Root>
      {/* <div> */}
      {/* <ApexCharts key={JSON.stringify(line)} data={line} /> */}
      {/* </div> */}
      <div>
        <DonutChart key={JSON.stringify(dashboardVal)} data={dashboardVal} />
      </div>
      <div>
        <div className="delta">
           
          <label htmlFor="periodSelect">Select Period:</label>
          <select
            id="periodSelect"
            onChange={(e) => handleSelectionChange(e.target.value)}
            value={selection}
          >
            <option value="2023">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2023">2021</option>
            <option value="2022">2020</option>
          </select>
        </div>
        <BarCharts key={JSON.stringify(bar)} data={bar} />
      </div>
    </Root>
  );
}

const Root = styled.section`
  display:flex;
  margin-top:20px;
  flex-wrap:wrap;
  width:100%;
  justify-content: space-around;
  align-items:center;
  gap:30px;
  >div{
    width: 450px !important;
  }
  .delta {
    display: flex;
    gap: 5px;
}

`;
