import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";

export default function Pagination({ totalCount, dataLimit, activePage }) {
  const [activeIndx, setActiveIndx] = useState(0);
  const pageAction = Math.ceil(totalCount / dataLimit);
  activePage(activeIndx + 1);

  useEffect(()=>{
    activePage(activeIndx + 1);
  },[activeIndx])

  return (
    <Root>
      <button
         onClick={() => {
            if (activeIndx> 0) {
              console.log("--->  activeIndx", activeIndx +1 , pageAction);
              setActiveIndx(activeIndx - 1);
            }
          }}
        className="btn"
      >
        <BsArrowBarLeft />
      </button>
      {Array(pageAction)
        .fill(0, 0, pageAction)
        .map((i, ix) => {
          return (
            <button key={ix}
              onClick={() => {
                setActiveIndx(ix);
              }}
              className={activeIndx == ix ? "btn onn" : "btn"}
            >
              {ix + 1}
            </button>
          );
        })}
      <button
        onClick={() => {
          if (activeIndx +1 < pageAction) {
            setActiveIndx(activeIndx + 1);
          }
        }}
        className="btn"
      >
        <BsArrowBarRight />
      </button>
    </Root>
  );
}

const Root = styled.section`
  display: flex;
  justify-content: center;
  align-items: stretch;

  .btn {
    width: 30px;
    height: 30px;
    margin: 3px;
    padding: 2px;
    border-radius: 5px;
    border: 0;
    font-size: 16px;
    align-self: center;
    vertical-align: center;
    color: white;
    background-color: #8656ec;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      color: #fff;
    }
  }

  .onn {
    background-color: #fff;
  }
`;
