export const adminLogin = {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
};
export const agentLogin = {
    AGENT_LOGIN: 'AGENT_LOGIN',
};

export const staffLogin = {
    STAFF_LOGIN: 'STAFF_LOGIN',
};

export const studentLogin = {
    STUDENT_LOGIN: 'STUDENT_LOGIN',
};

export const universityLogin = {
    UNIVERSITY_LOGIN: 'UNIVERSITY_LOGIN',
};