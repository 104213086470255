import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EXCHANGE_URLS_STUDENT } from "../URLS";
import axios from "axios";
import cogoToast from "cogo-toast";
// import logo from "../CommonPage/imageLogo/phanom.jpg";
import { RxCross2 } from "react-icons/rx";
// import{useNavigate} from "react-router-dom";


export default function Register({ onClose }) {
 
  const [add, setAdd] = useState({
    street_address: "",
    city: "",
    state: "",
    postal_code: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    email: "",
    phone_number: "",
    password: "",
  });

  const navigate = useNavigate();
  const registerApi = async () => {
    const { confirm_password, ...data } = formData;

    try {
      const res = await axios.post(`${EXCHANGE_URLS_STUDENT}/studentregister`, {
        ...data,
        address: add,
      });

      if (res?.status === 200) {
        cogoToast.success("Register SuccessFully");
        setFormData({
          username: "",
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          password: "",
        });
        setAdd({
          street_address: "",
          city: "",
          state: "",
          postal_code: "",
        });

        navigate("/studentlogin");
      }
    } catch (err) {
      cogoToast.error("Error");
    }
  };
  const handleRegisterClick = () => {
    if (formData?.password === formData?.confirm_password) {
      registerApi();
    } else {
      cogoToast.error("Password and Confirm Password Does Not Match");
    }
    if (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.phone_number &&
      formData.username &&
      formData.gender &&
      formData.dob
    ) {
    } else {
      cogoToast.warn("Please fill in all required fields");
    }
  };

  const isStepOneDataFilled = () => {
    return (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.phone_number &&
      formData.username &&
      formData.gender &&
      formData.dob
    );
  };

  return(
    <Root>
      <div className="Container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="register-main-div">
              <div className="row">
                <div className="col-lg-12">
                  <div className="close_but">
                    <RxCross2 onClick={onClose}/>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className="col-lg-4 sub-first-div">
                  <div className="log_img">
                    <img
                      src={logo}
                      alt="img"
                      height="50px"
                      className="logo_div"
                    />
                   
                  </div>
                  
                 

                </div> */}

                <div className="col-lg-12 sub-second-div">
                  <h3 className="text-center">Create account</h3>
                  {!isStepOneDataFilled() && (
                    <>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label>First name </label>
                          <br></br>
                          <input
                            type="name"
                            value={formData?.first_name}
                            onChange={(e) =>{
                              setFormData({
                                ...formData,
                                first_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Last name</label>
                          <br></br>
                          <input
                            type="name"
                            value={formData?.last_name}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                last_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Email Address</label>
                          <input
                            type="email"
                            value={formData?.email}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Phone Number </label>
                          <input
                            type="number"
                            value={formData?.phone_number}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                phone_number: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>User name </label>
                          <input
                            type="name"
                            value={formData?.username}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                username: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <select
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                gender: e.target.value,
                              });
                            }}
                          >
                            <option>Gender</option>
                            <option value={formData.gender.male}>Male</option>
                            <option value={formData.gender.female}>Female                          </option>
                            <option value={formData.gender.other}>Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label>Date of Birth</label>
                          <input
                            type="date"
                            value={formData?.dob}
                            onChange={(e) => {
                              setFormData({ ...formData, dob: e.target.value });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 mt-3">
                        <button>Save & Proceed</button>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-one">1</h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-two">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <h6>Already have an account?</h6>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a
                              href="studentlogin"
                              onClick={() => {
                                navigate("/studentlogin");
                              }}
                            >
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                   )} 
                </div>
              </div>

               <div className="row">
                {/* <div className="col-lg-4 sub-first-div"></div> */}
                <div className="col-lg-12 sub-second-div sec_div">
                    
                  {!isStepOneDataFilled() || (
                    <>
                      <div className="row mt-2">
                        <div class="col-lg-6 col-md-6 col-6">
                          <label> Street Address </label>
                          <br></br>
                          <input
                            value={add?.street_address}
                            onChange={(e) => {
                              setAdd({
                                ...add,
                                street_address: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> City </label>
                          <br></br>
                          <input
                            value={add?.state}
                            onChange={(e) => {
                              setAdd({ ...add, state: e.target.value });
                            }}
                            placeholder="State"
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> State</label>
                          <input
                            type="email"
                            value={formData?.state}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                state: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Postal Code </label>
                          <input
                            value={add.postal_code}
                            onChange={(e) => {
                              setAdd({ ...add, postal_code: e.target.value });
                            }}
                            placeholder="Postal Code"
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Password</label>
                          <input
                            type="password"
                            value={formData?.password}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <label> Confirm Password</label>
                          <input
                            value={formData?.confirm_password}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                confirm_password: e.target.value,
                              });
                            }}
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-6  col-md-6 col-6 mt-3">
                          <button type="submit" onClick={handleRegisterClick}>
                            Sign In
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5 col-md-5 col-4"></div>

                        <div className="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-one">1</h6>
                        </div>
                        <div class="col-lg-1 col-md-1 col-2">
                          <h6 className="indication-btn-twoo">2</h6>
                        </div>

                        <div className="col-lg-5"></div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-lg-7 col-md-7 col-7">
                          <div className="login-content-ist">
                            <h6>Already have an account?</h6>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-4">
                          <div className="link-content-second">
                            <a href="/studentlogin">
                              <b>Login</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>  
            </div>
          </div>
        </div>

        <div className="col-lg-2"></div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .close_but {
    display: flex;
    justify-content: flex-end;
    svg {
      width: 50px;
      height: 20px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .register-main-div {
    box-shadow: 1px 1px 4px 1px gray;
    background-color: #fff;
    margin: 80px 0 50px 0;
    border-radius: 20px;
  }

  .sub-first-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: none;
  }

  .log_img{
    display:flex;
    justify-content: center;
  }

  .main_img {
    padding:0 20px;
    img{
      width:100%;
      height:300px;
    }
}



  .sub-first-div .logo_div {
    
    margin-top: 10px;
  }

  .sub-second-div {
    border: none;
    padding: 0 100px 10px;
  }



  .sub-second-div h3 {
    padding-top: 10px;
  }

  .sub-second-div label {
    color: #4a4a4a;
    font-size: 12px;
    padding-left: 10px;
  }

  .sub-second-div input {
    border-radius: 10px;
    color: grey;
    height: 32px;
    border: 1px solid black;
    width: 100%;
  }

  select {
    border: 1px solid black;
    margin-top: 22px;
    font-size: 12px;
    color: #4a4a4a;
    width: 100%;
    height: 32px;
    border-radius: 10px;
  }
  .sub-second-div button {
    border: none;
    background-color: #8656ec;
    color: white;
    width: 100%;
    padding: 7px 20px;
    border-radius: 20px;
  }

  .indication-btn-one {
    color: white;
    background-color: #8656ec;
    margin-top: 20px;
    font-size: 12px;
    border-radius: 20px;
    padding: 5px 0px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .indication-btn-two {
    color: white;
    background-color: #c3c0c8;
    margin-top: 20px;

    font-size: 12px;

    padding: 5px 0px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .indication-btn-twoo {
    color: white;
    background-color: #8656ec;
    margin-top: 20px;
    font-size: 12px;
    padding: 5px 0px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .login-content-ist {
    border: none;
    height: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .login-content-ist h6 {
    color: #4a4a4a;
    font-size: 15px;
    text-decoration: none;
  }

  .login-content-second {
    border: none;
    height: 50px;
  }

  .login-content-second a {
    color: #8656ec !important;
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
  }

  @media (max-width: 567px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 28px;
    }
    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .sub-second-div {
      padding: 0 20px;
    }

    .sub-second-div button {
      padding: 9px 43px;
      font-size: 10px;
      margin-top: 10px;
    }

    .login-content-ist a {
      margin-left: 60%;
    }

    .indication-btn-one {
      font-size: 14px;
      padding: 10px 0px;
    }

    .indication-btn-two {
      font-size: 14px;
      padding: 10px 0px;
    }
  }
`;
