import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  studentLoginAction,
  userCheckAction,
  userDataAction,
} from "../../redux/users/action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";


export default function StudentLogin({ onClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    username: yup.string().required("Username is required."),
    password: yup.string().required("Password is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handelLogin = (e) => {
    const data = {
      username: e.username,
      password: e.password,
    };
    
    const userCallback = (response) => {
      dispatch(userDataAction(response?.data?.user));
      localStorage.setItem("token", response?.data?.user?.token);
      reset();
      navigate("/studash");
    };
    dispatch(userCheckAction(true));
    dispatch(studentLoginAction(data, userCallback));
  };

  const onSubmit = (data) => {
    handelLogin(data);
  };

  return (
    <Root>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4"></div>

            <div className="col-lg-4">
              <div className="login-main-div">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="close_but">
                      <RxCross2 onClick={onClose} />
                    </div>
                  </div>
                </div>
                <div className="cont_center">

                <h2 className="text-center">Welcome back Student,</h2>
                <div className="label_input">
                  <label>User name or email address</label>
                  <input type="username" {...register("username")} />
                  {errors.username && <p>{errors.username.message}</p>}
                </div>
                <div className="label_input">
                  <label>Password</label>
                  <div className="pswrd">
                    <input
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                    />
                    <button className="btn" onClick={togglePasswordVisibility}>
                      {showPassword ? <IoEyeSharp /> : <IoEyeOffSharp />}
                    </button>
                    {errors.password && <p>{errors.password.message}</p>}
                  </div>
                </div>
                <div className="password-div">
                  <button
                    onClick={() => {
                      navigate("/forgot");
                    }}
                  >
                    Forget your password
                  </button>
                </div>
                <div className="buttoonn">
                  <button type="submit">Login</button>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-7 col-12">
                    <h6 className="account-content">
                      Don't have an account{" "}
                      <button
                        onClick={() => {
                          navigate("/register");
                        }}
                      >
                        Sign up
                      </button>
                    </h6>
                  </div>
                </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4"></div>
          </div>
        </div>
      </form>
    </Root>
  );
}
const Root = styled.section`

.close_but {
    display: flex;
    justify-content: flex-end;
    svg {
      width: 50px;
      height: 20px;
      margin-top: 14px;
      cursor: pointer;
    }
  }

  
  .login-main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px gray;
    height: 350px;
    margin: 120px 0 50px 0;
    border-radius: 10px;
    @media (max-width: 777px){
      margin: 120px 10px 50px 10px;
    }

    .cont_center {
    align-items: center;
    display: flex;
    flex-direction: column;
}
    .label_input {
      display: flex;
      flex-direction: column;
      width: 60%;
      label {
        margin: 15px 5px 5px 5px;
        font-size: 12px;
      }
      input {
        width: 100%;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid black;
      }
      .btn {
        background: transparent;
        color: purple;
        width: 30px;
        margin: 0px;
        padding: 0px;
        position: relative;
        top: 0px;
        left: -29px;
        &:hover {
          cursor: pointer;
          color: blue;
        }
      }
    }
    .pswrd {
      display: flex;
      width: 110%;
    }
    h6 {
      width: 100% !important;
      display: flex;
      text-align: right;
      padding-top: 10px;
      button {
        color: #8656ec;
        background-color: #fff;
        border: none;
        margin-left: 5px;
        text-decoration: underline;
      }
    }
    h5 {
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
      text-align: right;
      padding-top: 5px;
      padding-right: 50px;
    }
  }

  .buttoonn {
    width: 180px;
    button {
      border: none;
      background-color: #8656ec;
      color: white;
      padding: 5px;
      margin: 5px;
      width: 100%;
      border-radius: 50px;
    }
  }

  .password-div {
    margin: 5px;
    width: 80%;
    text-align: right;
    padding-right: 40px;
    font-size: 12px;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #8656ec;
    }
    button{
      background-color: #fff;
      border: none;
      text-decoration: underline;
    }
  }
   
`;
