import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IoMenu } from "react-icons/io5";
import logo from "../CommonPage/imageLogo/phanom.png";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../redux/users/action";
import Login from "../CommonPage/Login";
import StudentLogin from "../StudentPortal/StudentLogin";
import UniLogin from "../universityPortal/UniLogin";
import UniRegister from "../universityPortal/UniRegister";
import Register from "../StudentPortal/Register";
import Partner from "../CommonPage/Partner";
import { RxCross1 } from "react-icons/rx";

export default function PreNav() {
  const navigate = useNavigate();
  const [activePop, setActivePop] = useState(false);
  const dispatch = useDispatch();
  const { modalIsOpen, modalType } = useSelector((state) => state?.users);

  const handleOpenModal = (type) => {
    dispatch(openModal(type));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const renderModalContent = () => {
    console.log("Modal_Type", modalType);
    switch (modalType) {
      case "uniregister":
        return <UniRegister onClose={handleCloseModal} />;
      case "register":
        return <Register onClose={handleCloseModal} />;
      case "partner":
        return <Partner onClose={handleCloseModal} />;
      case "login":
        return <Login onClose={handleCloseModal} />;
      case "studentLogin":
        return <StudentLogin onClose={handleCloseModal} />;
      case "uniLogin":
        return <UniLogin onClose={handleCloseModal} />;
      default:
        return null;
    }
  };
  return (
    <Root>
      <div
        className="logo"
        onClick={() => {
          handleCloseModal();
          navigate("/");
        }}
      >
        <img src={logo} alt="img" />
      </div>
      <div className="options">
        <button
          className="opt_btn"
          onClick={() => {
            handleOpenModal("uniregister");
          }}
        >
          University Register
        </button>
        <button
          className="opt_btn"
          onClick={() => {
            handleOpenModal("register");
          }}
        >
          Student Register
        </button>
        <button
          className="opt_btn"
          onClick={() => {
            handleOpenModal("partner");
          }}
        >
          Be a partner
        </button>
        <button
          className="login"
          onClick={() => {
            handleOpenModal("login");
          }}
        >
          AGENT LOGIN
        </button>
        <button
          className="login"
          onClick={() => {
            handleOpenModal("studentLogin");
          }}
        >
          STUDENT LOGIN
        </button>
        <button
          className="login"
          onClick={() => {
            handleOpenModal("uniLogin");
          }}
        >
          UNIVERSITY LOGIN
        </button>
      </div>

      <div
        className="menu"
        onClick={() => {
          setActivePop(true);
        }}
      >
        <IoMenu />
      </div>

      <div
        className={activePop ? "pop_nav" : "no_pop"}
        onClick={() => {
          setActivePop(false);
        }}
      >
        <div className="cancle">
          <RxCross1 />
        </div>
        <div
          className="opt_btn"
          onClick={() => {
            handleOpenModal("uniregister");
          }}
        >
          UNIVERSITY REGISTER
        </div>

        <div
          className="opt_btn"
          onClick={() => {
            handleOpenModal("register");
          }}
        >
          STUDENT REGISTER
        </div>
        <div
          className="opt_btn"
          onClick={() => {
            handleOpenModal("partner");
          }}
        >
          BE A PARTNER
        </div>

        <div
          className="login"
          onClick={() => {
            handleOpenModal("login");
          }}
        >
          AGENT LOGIN
        </div>

        <div
          className="login"
          onClick={() => {
            handleOpenModal("studentLogin");
          }}
        >
          STUDENT LOGIN
        </div>
        <div
          className="login"
          onClick={() => {
            handleOpenModal("uniLogin");
          }}
        >
          UNIVERSITY LOGIN
        </div>
      </div>
      {modalIsOpen && <div className="open_page">{renderModalContent()}</div>}
    </Root>
  );
}

const Root = styled.section`
  display: flex;
  height: 10%;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  position: fixed;
  width: 100%;
  padding: 20px;
  background-image: linear-gradient(
    to right,
    #c6a5ffc7,
    #c6a5ffc7,
    #c6a5ff63,
    #ffffff,
    #c6a5ff24
  );
  font-size: 13px;
  box-shadow: 1px 1px 6px 1px #c6a5ff;
  @media (max-width: 978px) {
    justify-content: space-between;
  }

  .open_page {
    z-index: 1000;
    position: fixed;
    top: 64px;
    left: 1px;
    width: 100%;
    backdrop-filter: blur(5px);
  }
  .logo {
    width: 12%;
    img {
      width: 70px;
      height: 60px;
    }
    @media (max-width: 878px) {
      width: 50%;
      img {
        width: 50%;
      }
    }
  }
  .options {
    flex: 1;
    display: flex;
    justify-content: right;
    gap: 10px;
    button {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      padding: 7px;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: lightgray;
        box-shadow: 10px 5px 5px gray;
      }
    }

    .login {
      color: #ffffff;
      padding: 5px;
      border-color: transparent;
      cursor: pointer;
      background: #c6a5ff;
      background-size: 300% 100%;
      transition: all 0.2s ease-in-out 0s;
      &:hover {
        background: #8656ec;
      }
    }

    @media (max-width: 950px) {
      display: none;
    }
  }
  .menu {
    @media (max-width: 950px) {
      display: block;
      padding-right: 10px;
      cursor: pointer;
      svg {
        color: #8656ec;
        width: 20px;
        height: 20px;
      }
    }
  }
  .menu {
    @media (min-width: 951px) {
      display: none;
    }
  }

  .pop_nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 40px;
    right: 11px;
    height: 230px;
    z-index: 10000;
    width: 180px;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 3px 1px gray;
    gap: 10px;
    color: #8656ec;
    cursor: pointer;
    background: #fff;
    .cancle {
      text-align: right;
    }
  }
  .no_pop {
    display: none;
  }
`;
