import React from "react";
import styled from "styled-components";
import ProfileUser from "../MainLayouts/Profile/ProfileUser";
import ProfileAddress from "../MainLayouts/Profile/ProfileAddress";

export default function StuDashboard() {
  return (
    <Root>
      <h3>Dashboard</h3>
      <div>
        <ProfileUser />
      </div>
      <div>
        <ProfileAddress />
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding-left: 90px;
  @media (max-width: 788px) {
    padding-left: 60px;
  }
  h3{
    margin: 5px;
  }
`;
