import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EXCHANGE_URLS_APPLICATION } from "../../URLS";
import { useDispatch } from "react-redux";
import { BsFillEyeFill } from "react-icons/bs";
import Download from "./Download";
import { appDetailsAction, loaderAction } from "../../../redux/users/action";
import { useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import Pagination from "../../Pagination";

export default function History({ popUser = () => {} }) {
  const [applications, setApplications] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [activePage, setActivePage] = useState("");
  const [courses, setCourses] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataLimit = 100;

  // const getHistory = async (searchKey) => {
  //   const axiosConfig = {
  //     headers: {
  //       authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };
  //   const params = {
  //     limit: dataLimit,
  //     page: activePage,
  //   };
  //   try {
  //     const res = await axios.get(
  //       `${EXCHANGE_URLS_APPLICATION}/fetchallapplications?searchKey=${searchKey}`,
  //       axiosConfig
  //     );
  //     setApplications(res?.data?.data[0].applications);
  //   } catch (e) {
  //     console.error("error", e);
  //   }
  // };

  const getCourses = async (searchKey) => {
    const axiosConfig = {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const params = {
      limit: dataLimit,
      page: activePage,
    };
    try {
      const res = await axios.get(
        `${EXCHANGE_URLS_APPLICATION}/fetchallapplications?page=1&pageSize=100&?searchKey=${searchKey}`,
        axiosConfig
      );
      setCourses(res?.data?.data?.applications);
      setApplications(res?.data?.data.applications);
      console.log("resssssss", res?.data?.data?.applications);
    } catch (e) {
      cogoToast.error("error");
    }
  };
  const handleSearch = () => {
    setCourses();
  };

  useEffect(() => {
    dispatch(loaderAction(true));
    if (searchKey != null) {
      const debounce = setTimeout(() => {
        getCourses(searchKey);
      }, 1000);

      return () => {
        clearTimeout(debounce);
      };
    }
  }, [searchKey, activePage]);
  const handlePassData = (i) => {
    dispatch(appDetailsAction(i));
    popUser(true);
  };

  const uniqueApplications =
    applications &&
    applications.reduce((uniqueArray, currentItem) => {
      const isUnique = uniqueArray.some(
        (item) => item?.application_id === currentItem?.application_id
      );
      if (!isUnique) {
        uniqueArray.push(currentItem);
      }
      return uniqueArray;
    }, []);

  return (
    <Root>
      <div className="header">
        <h1>Application History</h1>
        <Download />
      </div>
      <div className="search_box">
        <input
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
          placeholder="Search Courses By Name Or Number..."
        ></input>
        <button
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </button>
      </div>
      <div className="app_table">
        <div className="app_header">
          <div>CAMS Id</div>
          <div>Student Name</div>
          <div>University Name</div>
          <div>Course Name</div>
          <div>Status</div>
          <div>View</div>
        </div>
        {uniqueApplications &&
          uniqueApplications.map((i, index) => {
            return (
              <div
                className="app_body"
                onClick={() => {
                  handlePassData(i);
                }}
                key={index}
              >
                <div className="cams">#{i?.application_id}</div>
                <div>
                  <p>
                    <span>{i?.student_firstname}</span>
                  </p>
                  <p>
                    Passport No: <span>{i?.student_passport_no}</span>
                  </p>
                  <p>
                    Counsellor : <span>{i?.user_id.username}</span>
                  </p>
                </div>
                <div>
                  <h6>{i?.university_id.university_name}</h6>
                  <p className="person">{i?.university_id.person_name}</p>
                  <p className="person">{i?.university_id.contact_number}</p>
                </div>
                <div>{i?.course_id?.course_name}</div>
                <div>{i?.application_status}</div>
                <div
                  className="iconn"
                  onClick={() => {
                    navigate(`/detailview/${i?.application_id}`);
                  }}
                >
                  <BsFillEyeFill />
                </div>
              </div>
            );
          })}
      </div>
      <Pagination
        totalCount={totalCount}
        dataLimit={dataLimit}
        activePage={(e) => {
          setActivePage(e);
        }}
      />
    </Root>
  );
}
const Root = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  color: #202020;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  align-items: center;
  height: 100%;
  padding-left: 80px;
  @media (max-width: 788px) {
    padding-left: 60px;
  }
  .header {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 10px;
    @media (max-width: 566px) {
      padding: 10px;
      flex-direction: column;
      h1 {
        font-size: small;
      }
    }
    h1 {
      color: #202020;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 700;
    }
    button {
      width: 170px;
      height: 40px;
      margin: 5px;
      border-radius: 50px;
      border: none;
      background: #57be1f;
      color: #ffffff;
      &:hover {
        box-shadow: 5px 5px 7px gray;
      }
      @media (max-width: 566px) {
        width: 100%;
        height: 30px;
      }
    }
  }
  .search_box {
    display: flex;
    margin-left: 10px;
    width: 50%;
    height: 60px;
    background: #ffffff;
    border-radius: 50px;
    box-shadow: 4px 5px 6px gray;
    @media (max-width: 566px) {
      width: 90%;
    }
    input {
      border: 1px solid gray;
      width: 100%;
      padding-left: 10px;
      border-radius: 50px;
      margin: 10px;
    }
    button {
      width: 80px;
      background: #8656ec;
      padding: 5px;
      border: none;
      border-radius: 50px;
      float: none;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 700;
      margin: 15px;
      color: #ffffff;
    }
  }

  p {
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    text-align: left;
    font-family: "open-sans", "sana-serif";
  }

  .app_table {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 80%;
    padding: 5px 5px 5px 10px;
    font-family: "Roboto", "sana-serif";
    @media (max-width: 677px) {
      width: 100%;
    }
    .app_header {
      display: flex;
      background: #f7f6fe;
      text-align: center;
      color: gray;
      border-bottom: 4px solid #c6abff;

      > div {
        flex: 1;
        border: 1px solid #dee2e6;
        padding: 15px;
      }
    }
    .app_body {
      display: flex;
      font-family: "Roboto", sans-serif;

      .cams {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .iconn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > div {
        flex: 1;
        border: 0.3px solid #fbfbfd;
        text-transform: capitalize;
        background-color: #e7e7e8;
        text-align: center;
        padding: 15px 5px;
        .person {
          color: #8995ad;
          font-size: 14px;
          @media (max-width: 789px) {
            font-size: 10px;
          }
        }
        h6 {
          font-weight: 600;
          text-align: left;
          font-size: small;
          @media (max-width: 789px) {
            font-size: 12px;
          }
        }

        span {
          font-weight: 600;
        }
        &:nth-child(odd) {
          background-color: #c6abff42;
        }

        &:nth-child(even) {
          background-color: white;
        }
      }
      &:hover {
        background-color: lightgray;
        cursor: pointer;
      }
    }
  }
  svg {
    height: 25px;
    width: 25px;
    color: #754cf2;
    &:hover {
      color: green;
    }
  }
  @media (max-width: 568px) {
    .app_table {
      font-size: smaller;
      margin: 0px;
      overflow-x: scroll;
      .app_header {
        min-width: 135px;
        /* width: 100%; */
        > div {
          padding: 5px;
          font-size: smaller;
        }
      }
      .app_body {
        min-width: 105px;
        width: 100%;
        > div {
          /* flex: 1; */
          font-size: smaller;
        }
      }
    }
  }
`;
